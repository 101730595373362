<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col lg="2" md="2" sm="12" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>
<!-- 
            <b-col lg="3" md="3" sm="12" class="d-flex align-items-left justify-content-end mb-1 mb-md-0 ">
              <treeselect value-consists-of="LEAF_PRIORITY" :multiple="true" :options="statusOptions" v-model="treeStatus" valueFormat="object" :limit="2" class="per-page-selector d-inline-block w-100" placeholder="Filter Report" />
            </b-col> -->

      
            <!-- Search -->

            <b-col lg="10" md="10" sm="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
                <b-button variant="primary" router-link to="/accounting/add-account" >
                  <span class="text-nowrap">New Account</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

      
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :items="listAccounts"
          :filter="filter"
          filter-debounce="250"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="quoteFields"
          show-empty
          empty-text="No matching records found"
          @row-selected="onRowSelected"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!-- Column: Id -->
          <!-- Column: Id -->
          <template #cell(accountNumber)="data">
            <!-- <b-link :to="{ name: 'inventory-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
            <strong class="text-primary">#{{ data.item.accountNumber ? data.item.accountNumber : 'N/A' }}</strong>
          </template>

          <template #cell(accountTitle)="data">
            <span class="font-weight-bold"> {{ data.item.accountTitle ? data.item.accountTitle : 'N/A' }} </span>
          </template>

      
          <!-- Column: Listing Price -->
          <template #cell(balance)="data">
            <span class="font-weight-bold"> {{ data.item.balance ? formatPrice(data.item.balance, 2) : formatPrice(0) }} </span>
          </template>

          <template class="font-weight-bold" #cell(accountType)="data">
            <span class="font-weight-bold"> {{ data.item.accountType ? data.item.accountType : 'N/A' }} </span>
          </template>

   
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import store from '@/store';
import accountingStoreModule from '@/views/accounting/accountingStoreModule.js';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: { Treeselect, vSelect, BImg, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BOverlay, BFormGroup, flatPickr },

  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = 'accounting';

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, accountingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    const periodOptions = [
      // { text: 'Website Status Selection', value: '' },
      { text: 'This Month', value: 'A' },
      { text: 'This Quarter', value: 'B' },
      { text: 'This Year', value: 'C' },
      { text: 'Last Month', value: 'D' }, // { text: "Last Month", value: "A" },
      { text: 'Last Quarter', value: 'E' }, // { text: "Last Year", value: "B" },
      { text: 'Last Year', value: 'F' }, // { text: "Last Year", value: "B" },
      { text: 'All Time', value: 'G' },
    ];

    const statusWebsiteOptions = [
      // { text: 'Website Status Selection', value: '' },
      { text: 'Published', value: 'yes' },
      { text: 'Unpublished', value: 'no' },
    ];

    const tempStatusOptions = [
      // { text: 'Inventory Status Selection', value: '' },
      { text: 'Created', value: 'A' },
      { text: 'Incoming - Consigment', value: 'B' },
      { text: 'In Stock - Consigment', value: 'C' },
      { text: 'Incoming', value: 'D' },
      { text: 'In Stock Pending', value: 'I' },
      { text: 'In Stock - Pending Receiving', value: 'E' },
      { text: 'In Stock - Pending Payment', value: 'F' },
      { text: 'In Stock', value: 'G' },
      { text: 'Created', value: 'P' },

      { text: 'Sold Pending', value: 'PA' },
      { text: 'Delivered - Pending Payment', value: 'PB' },
      { text: 'Sold', value: 'PC' },
      { text: 'Delivered', value: 'PD' },
      { text: 'Closed', value: 'PE' },

      { text: 'In Stock Pending - Sold Order', value: 'PF' },
      { text: 'In Stock Pending - Sold Order', value: 'PL' },

      { text: 'Incoming - Sold Order', value: 'PG' },
      { text: 'Consigment - Sold Order', value: 'PH' },
      { text: 'Sold Order', value: 'PI' },
      { text: 'Funded - Pending Delivery', value: 'PK' },
    ];

    const tempQuoteStatusOptions = [
      { label: 'Draft', value: 'A' },
      { label: 'Pending Manager', value: 'B' },
      { label: 'Approved', value: 'C' },
      { label: 'Not Approved', value: 'D' },
      { label: 'Sent To Client', value: 'E' },
      { label: 'Win', value: 'F' },
      { label: 'Loss', value: 'P' },
      { label: 'Canceled Sale', value: 'J' },
      { label: 'Win-Closed', value: 'H' },
    ];

    const userOptions = [];

    return {
      userheaders: ['stockNumber', 'purchasePrice', 'cogsTotal', 'purchaseTotal', 'cogsTotalIncome', 'sellingPrice', 'grossProfit'],
      filter: null,
      filterOn: ['accountNumber','accountTitle','accountType'],

      quoteFields: [
        { key: 'accountNumber', label: 'NUMBER', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'accountTitle', label: 'NAME', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'balance', label: 'BALANCE', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'accountType', label: 'TYPE', class: 'text-center', thStyle: 'width: 30%' },
     
      ],

      config: {
        dateFormat: 'd/m/Y',
        mode: 'range',
      },
      userOptions,
      treeStatus: null,

      perPage: localStorage.getItem('inventoryShow') ?? 8,
      fetchAccounts: [],
      listAccounts: [],
      currentPage: 1,
      perPageOptions: [5, 8, 25, 50, 100],
      tempQuoteStatusOptions,
      totalRows: 0,
      baseURL: store.state.app.baseURL,
      loading: false,
      statusWebsiteOptions,
      tempStatusOptions,
      statusOptions: [],
      statusWebsite: null,
      status: null,
      colWidthFactor: null,
      periodOptions,
      selectedPeriod: null,
      totalPurchasePrice: null,
      totalSellingPrice: null,
      totalCostOfSales: null,
      totalProfit: null,
      filterDateStart: null,
      filterDateEnd: null,
      inHeaders: [],
      rangeDate: null,
      profitClass: null,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    treeStatus: {
      handler: function(val, before) {
        if (before != null) {
          if (val.length != before.length) {
            this.getItems();
          }
        }
      },
    },

    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStart = this.rangeDate.split(' to ')[0];
            this.filterDateEnd = this.rangeDate.split(' to ')[1];

            this.getItems();
          }
        }
      },
    },

    perPage: {
      handler: function(val, before) {
        localStorage.setItem('inventoryShow', val);
      },
    },
  },

  methods: {
    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.rangeDate = null;
        this.getItems();
      }
    },
    calculateClass() {
      if (this.totalProfit > 0) {
        this.profitClass = 'text-success';
      } else {
        this.profitClass = 'text-danger';
      }
    },

    sellTotal(item) {
      return item.sellingPrice + item.cogsTotalIncome;
    },
   
    onRowSelected(item) {
      if (this.$Can('inventory_show')) {
        router.push({ name: 'account-preview', params: { id: item[0].id } });
      }    },

    onRowPurchaseSelected(item) {
      if (this.$Can('purchase_show')) {
        router.push({ name: 'quote-preview', params: { id: item[0].id } });
      }
    },

    onRowSaleSelected(item) {
      if (this.$Can('sale_show')) {
        router.push({ name: 'sales-preview', params: { id: item[0].id } });
      }
    },
    onError(event) {
      event.target.src = '../no-car.png';
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('DD/MM/YYYY');
      }
    },

    getItems() {
      this.loading = true;
      store
        .dispatch('accounting/listAccount')
        .then((res) => {
          // let filterObj = {
          //   perPage: this.perPage,
          //   status: this.treeStatus ? this.treeStatus : [],
          // };

          // localStorage.setItem('filterSaleReport', JSON.stringify(filterObj));

          // this.totalRows = res.data.data.length;
          // this.totalPurchasePrice = res.data.totalPurchase;
          // this.totalSellingPrice = res.data.sellingTotal;
          // this.totalProfit = res.data.totalProfit;
          // this.totalCostOfSales = res.data.totalCostOfSales;
          // this.filterDateStart = res.data.startDate;
          // this.filterDateEnd = res.data.endDate;

          // res.data.data.forEach((element) => {
          //   element._showDetails = false;
          // });

          this.fetchAccounts = res.data;
          this.listAccounts = res.data;

          // if (res.data.headers) {
          //   res.data.headers.forEach((items) => {
          //     this.colWidthFactor = this.colWidthFactor + res.data.columns.find((x) => x.value == items).percentage;
          //   });

          //   res.data.headers.forEach((items) => {
          //     var columns = res.data.columns.find((x) => x.value == items);
          //     var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          //     this.inHeaders.push({ key: items, label: columns.title, sortable: true, class: 'text-center', thStyle: width });
          //   });
          //   this.calculateClass();

          //   this.loading = false;
          // }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    filterTreeSelect() {
      this.statusOptions = [];
      let treeOptionsStatus = [
        {
          id: 1000000,
          label: 'Users',
          children: [],
        },
      ];

      this.userOptions.forEach((element) => {
        let setStatus = {
          id: element.value,
          label: element.label,
          value: element.value,
          is: 'status',
        };
        treeOptionsStatus[0].children.push(setStatus);
      });

      this.statusOptions = treeOptionsStatus;
      this.storageSaleItem();
    },

    storageSaleItem() {
      let key = JSON.parse(localStorage.getItem('filterSaleReport'));

      if (key == null) {
        this.getItems();
      }

      if (key) {
        // this.perPage = key.perPage ? key.perPage : 10;
        // this.page = key.page ? key.page : 1;

        let array = [];
        if (key.status.length > 0) {
          key.status.forEach((element) => {
            array.push(element);
          });
        }
        this.treeStatus = array;
        this.getItems();
      }
    },

    getUsers() {
      this.loading = true;
      store.dispatch('accounting/fetchUsers', {}).then((res) => {
        this.userOptions = res.data;
        this.filterTreeSelect();
      });
    },
  },

  created() {
    // this.getUsers();

    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
